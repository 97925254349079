import React from 'react';
import './index.less';
import { getClassName, noop } from '../Base';
import classnames from 'classnames';
import { getStaticUrl } from '../../api/static';
import { Tooltip } from '../AntD';

export interface TicketNodePrimaryProps {
  /**
   * 标题处的数字
   * @default ''
   */
  number?: string;

  /**
   * 标题
   * @default ''
   */
  title?: string;

  /**
   * 颜色
   * @default blud
   */
  colorStyle?: 'blue' | 'green' | 'gray' | 'green-process' | 'blue-process';

  /**
   * 底部样式
   * @default normal
   */
  footerStyle?: 'empty' | 'normal' | 'arrow' | 'empty-arrow';

  /**
   * item 的提示信息
   * @default ''
   */
  itemTooltip?: string;

  /**
   * 条目内容
   */
  itemList?: {
    text: string;
    iconStyle?: 'green' | 'gray' | 'process' | 'wait-upload' | 'wait-review';
    textStyle?: 'normal' | 'gray';
    itemStyle?: 'normal' | 'selected';
    actionList?: string[];
    indent?: number;
    origin?: Record<string, any>;
  }[];

  /**
   * 事件回调内容
   * @default noop
   */
  onEvent?: (
    event:
      | 'NEW_BILL'
      | 'TASK-VIEW'
      | 'TASK-NEW'
      | 'TASK-MODIFY'
      | 'TASK-REVIEW'
      | 'TASK-VIEWATTR'
      | 'ITEM'
      | 'TASK-ADD',
    params?: Record<string, any>,
  ) => void;

  /**
   * 节点高度
   * @default 220px
   */
  height?: string;
}

export const TicketNodePrimary = (props: TicketNodePrimaryProps): React.ReactElement => {
  const {
    number = '',
    title = '',
    colorStyle = 'blue',
    footerStyle = 'empty',
    itemTooltip = '',
    itemList = [],
    height = '220px',
    onEvent = noop,
  } = props;
  const itemStyle = !!itemTooltip ? 'highlight' : '';
  const nodeStyle = { height };
  return (
    <div className={classnames(getClassName('TicketNodePrimary'), colorStyle)} style={nodeStyle}>
      <div className="wrapper">
        <div className="head-shadow" />
        <div className="head">
          <div className="inner">
            <div className="order">{number}</div>
            <div className="title" title={title}>
              {title}
            </div>
            <div className="arrow" />
          </div>
        </div>
        <div className={classnames('content', `footer-${footerStyle}`)}>
          <div className={classnames('inner', itemStyle)}>
            {itemList.map((obj) => {
              const style: Record<string, any> = {};
              if (obj.indent) {
                style.paddingLeft = obj.indent + 12;
              }
              return (
                <div
                  className={classnames('item', obj.itemStyle)}
                  key={obj?.origin?.id || obj.text}
                  style={style}
                >
                  <div className={classnames('icon', obj.iconStyle || 'green')}>
                    <img src={getStaticUrl('finish.svg')} className="green" />
                    <img src={getStaticUrl('todo.svg')} className="gray" />
                    <img src={getStaticUrl('process.svg')} className="process" />
                    <img src={getStaticUrl('wait-upload.png')} className="wait-upload" />
                    <img src={getStaticUrl('wait-review.png')} className="wait-review" />
                  </div>
                  <div className="action">
                    {(obj.actionList || []).map((action) => {
                      if (action === '$VIEW') {
                        return (
                          <span
                            title="查看详情"
                            key={action}
                            className="icon"
                            onClick={() => onEvent('TASK-VIEW', { task: obj })}
                          >
                            <img src={getStaticUrl('view.svg')} />
                          </span>
                        );
                      }
                      if (action === '$VIEWATTR') {
                        return (
                          <span
                            title="查看附件"
                            key={action}
                            className="icon"
                            onClick={() => onEvent('TASK-VIEWATTR', { task: obj })}
                          >
                            <img src={getStaticUrl('viewattr.svg')} />
                          </span>
                        );
                      }
                      if (action === '$MODIFY') {
                        return (
                          <span
                            title="修改"
                            key={action}
                            className="icon"
                            onClick={() => onEvent('TASK-MODIFY', { task: obj })}
                          >
                            <img src={getStaticUrl('modify.svg')} />
                          </span>
                        );
                      }
                      if (action === '$REVIEW') {
                        return (
                          <span
                            title="审阅"
                            key={action}
                            className="icon"
                            onClick={() => onEvent('TASK-REVIEW', { task: obj })}
                          >
                            <img src={getStaticUrl('review.svg')} />
                          </span>
                        );
                      }
                      if (action === '$NEW') {
                        return (
                          <span
                            title="录入"
                            key={action}
                            className="icon"
                            onClick={() => onEvent('TASK-NEW', { task: obj })}
                          >
                            <img src={getStaticUrl('new.svg')} />
                          </span>
                        );
                      }
                      if (action === '$ADD') {
                        return (
                          <span
                            title="新增补充协议"
                            key={action}
                            className="icon"
                            onClick={() => onEvent('TASK-ADD', { task: obj })}
                          >
                            <img src={getStaticUrl('add-primary.svg')} />
                          </span>
                        );
                      }

                      return (
                        <span className="btn" key={action}>
                          {action}
                        </span>
                      );
                    })}
                  </div>
                  <div
                    className={classnames(
                      'text',
                      obj.textStyle,
                      'action-list-' + (obj.actionList || []).length.toString(),
                    )}
                    onClick={() => onEvent('ITEM', { item: obj })}
                  >
                    {!!itemTooltip ? (
                      <Tooltip title={itemTooltip}>
                        <span>{obj.text}</span>
                      </Tooltip>
                    ) : (
                      <span title={obj.text}>{obj.text}</span>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          <div className={classnames('footer', footerStyle)}>
            <div className="action normal" onClick={() => onEvent('NEW_BILL')}>
              <span className="icon">
                <img src={getStaticUrl('add.svg')} />
              </span>
              <span>新增提单</span>
            </div>
            <div className="action arrow" onClick={() => onEvent('NEW_BILL')}>
              <span className="icon">
                <img src={getStaticUrl('add-primary.svg')} />
              </span>
              <span>新增提单</span>
            </div>
            <div className="action empty-arrow">
              <span>新增提单</span>
            </div>
          </div>
        </div>
      </div>
      <div className={classnames('arrow-wrapper', footerStyle)}>
        <div className="outer">
          <div className="inner">
            <div className="arrow" />
          </div>
        </div>
      </div>
    </div>
  );
};
