import React from 'react';
import './index.less';
import { getClassName, noop } from '../Base';
import { getStaticUrl } from '../../api/static';

interface EmptyBlockProps {
  text?: string;
}

export const EmptyBlock = (props: EmptyBlockProps): React.ReactElement => {
  const { text = '请选择业务单' } = props;
  return (
    <div className={getClassName('EmptyBlock')}>
      <div className="wrapper">
        <div className="img">
          <img src={getStaticUrl('empty.png')} />
        </div>
        <div className="text">{text}</div>
      </div>
    </div>
  );
};
