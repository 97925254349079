import React, { useState } from 'react';
import { noop } from '../Base';
import { CooperationProcess } from '../../api/resource';
import { Select } from '../AntD';
const Option = Select.Option;

export const BillList = (props: {
  orderId?: number;
  onChange?: (id: number) => void;
}): React.ReactElement => {
  const { orderId, onChange = noop } = props;
  const taskReq = CooperationProcess({ bizId: orderId, bizType: 'order' }).useRequest({ ready: !!orderId });
  const nodeList = taskReq.data?.data?.nodeList || [];
  const itemList: { name: string; value: number }[] = [];
  nodeList
    .filter((obj) => obj.nodeCode === 'billManager')
    .forEach((node) => {
      (node.taskList || []).forEach((task) => {
        itemList.push({ value: task.bizId as number, name: task.taskCode });
      });
    });
  return (
    <Select style={{ width: 300 }} onChange={onChange}>
      {itemList.map((obj) => {
        return (
          <Option value={obj.value} key={obj.value}>
            {obj.name}
          </Option>
        );
      })}
    </Select>
  );
};
