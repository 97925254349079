import React from 'react';
import './index.less';
import { getClassName, noop } from '../Base';
import { getStaticUrl } from '../../api/static';

interface TicketArrowProps {
  /**
   * 是否反向
   * @default false
   */
  inverse?: boolean;

  /**
   * 类型
   * @default gray
   */
  colorStyle?: 'gray' | 'blue' | 'green' | 'weak';
}

export const TicketArrow = (props: TicketArrowProps): React.ReactElement => {
  const { inverse = false, colorStyle = 'gray' } = props;
  const urlMap: Record<string, string> = {
    blue: 'arrow-blue.svg',
    green: 'arrow-green.svg',
    gray: 'arrow-gray.svg',
    weak: 'arrow-weak.svg',
  };
  const img = getStaticUrl(urlMap[colorStyle]);
  return (
    <div className={getClassName('TicketArrow')}>
      <img src={img} className={inverse.toString()} />
    </div>
  );
};
