import React from 'react';
import './index.less';
import { getClassName, noop } from '../Base';
import { getStaticUrl } from '../../api/static';
import { Search } from '../Search';

interface HeaderProps {
  name?: string;
}

export const Header = (props: HeaderProps): React.ReactElement => {
  const { name = '' } = props;
  return (
    <div className={getClassName('Header')}>
      <div className="wrapper">
        <div className="left">
          <div className="logo">
            <img src={getStaticUrl('logo.svg')} />
          </div>
          <div className="title">雁群</div>
        </div>
        <div className="middle">
          <div className="menu">
            <div className="item true">工作台</div>
            <div className="item">驾驶舱</div>
            <div className="item">我的数据库</div>
          </div>
        </div>
        <div className="right">
          <div className="query">
            <Search />
          </div>
          <div className="user">
            <div className="avatar">
              <img src={getStaticUrl('rocket.svg')} />
            </div>
            <div className="name" title={name}>
              {name || '--'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
