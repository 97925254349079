import React, { useState } from 'react';
import './index.less';
import { getClassName, noop } from '../Base';
import classnames from 'classnames';
import { Button, Tooltip, Input, Radio, Select } from '../AntD';
import { getType } from '../../utils/get-type';

const RadioGroup = Radio.Group;
const TextArea = Input.TextArea;

const FIELD_TYPE = {
  INPUT: 'input',
  SELECT: 'select',
  RADIO: 'radio',
  PASSWORD: 'password',
  TEXTAREA: 'textarea',
};

const genField = (
  field: TypeField,
  value: string | undefined,
  onChange: (code: string, value: string) => void,
  formValue: Record<string, any>,
): React.ReactElement | null => {
  if (value === undefined && field.defaultValue !== undefined) {
    setTimeout(() => {
      onChange(field.code, field.defaultValue);
    }, 30);
  }

  if (field.type === FIELD_TYPE.INPUT) {
    return (
      <Input
        placeholder={field.placeholder}
        value={value}
        disabled={!!field.params?.disabled}
        onChange={(e) => onChange(field.code, e.target.value)}
      />
    );
  }
  if (field.type === FIELD_TYPE.TEXTAREA) {
    return (
      <TextArea
        placeholder={field.placeholder}
        value={value}
        disabled={!!field.params?.disabled}
        onChange={(e) => onChange(field.code, e.target.value)}
      />
    );
  }

  if (field.type === FIELD_TYPE.PASSWORD) {
    return (
      <Input
        placeholder={field.placeholder}
        type="password"
        value={value}
        onChange={(e) => onChange(field.code, e.target.value)}
      />
    );
  }

  if (field.type === FIELD_TYPE.SELECT) {
    const options = (field.params?.optionList || []).map((val: string) => {
      return { label: val, value: val };
    });
    return (
      <Select
        style={{ width: field.params?.width || '100%' }}
        placeholder={field.placeholder}
        value={value}
        options={options}
        onChange={(v) => onChange(field.code, v)}
      />
    );
  }

  if (field.type === FIELD_TYPE.RADIO) {
    const options = field.params?.optionList || [];
    return (
      <RadioGroup value={value} onChange={(e) => onChange(field.code, e.target.value)}>
        {options.map((obj: { value: string; label: string }) => {
          return (
            <Radio value={obj.value} key={obj.value}>
              {obj.label}
            </Radio>
          );
        })}
      </RadioGroup>
    );
  }

  const Cls = field.type;
  return (
    <Cls
      {...(field?.params?.params || {})}
      placeholder={field.placeholder}
      value={value}
      formValue={formValue}
      onChange={(val: string) => onChange(field.code, val)}
    />
  );
};

type TypeField = {
  code: string;
  name: string;
  type:
    | string
    | ((
        props: { placeholder: string; value: any; formValue: Record<string, any>; onChange: any } & Record<
          string,
          any
        >,
      ) => React.ReactElement);
  tip?: string;
  defaultValue?: any;
  placeholder?: string;
  required?: boolean;
  ignore?: boolean;
  params?: Record<string, any>;
};

interface CrudListFormProps {
  /**
   * 配置项，具体参见 Demo
   */
  config: TypeField[] | TypeField[][];

  /**
   * 当前表单的值
   * @default {}
   */
  value?: Record<string, string>;

  /**
   * 提交时的回调
   * @default noop
   */
  onSubmit?: (value: Record<string, string>) => void;

  /**
   * 单个表单项变化时回调
   * @default noop
   */
  onChange?: (code: string, value: string) => void;

  /**
   * 是否显示提交按钮
   * @default true
   */
  showSubmit?: boolean;

  /**
   * 样式类型，会直接加到 wrapper 节点的 className 上
   * @default ''
   */
  mode?: string;
}

export const CrudListForm = (props: CrudListFormProps): React.ReactElement => {
  const { config, value = {}, onSubmit = noop, onChange = noop, showSubmit = true, mode = '' } = props;
  const [formValue, setFormValue] = useState<{ [index: string]: string }>(value);
  const onValueChange = (code: string, value: string) => {
    setFormValue({
      ...formValue,
      [code]: value,
    });
    onChange(code, value);
  };

  const genItem = (field: TypeField) => {
    if (field.ignore) {
      return null;
    }
    return (
      <div className="item" key={field.code}>
        {field.name ? (
          <div className="name">
            <span>{field.name}</span>
            {field.required ? <span className="star">*</span> : null}
            {field.tip ? (
              <Tooltip title={field.tip}>
                <img src="https://chatbot.sp-cdn.shopee.com/web/AnoxwFAA1AqM1=gRCgAARw.svg" />
              </Tooltip>
            ) : null}
          </div>
        ) : null}
        <div className="value">
          {genField(field, formValue[field.code]?.toString(), onValueChange, formValue)}
        </div>
      </div>
    );
  };

  const genGroup = (groupConfig: TypeField[]) => {
    if (!groupConfig.length) {
      return null;
    }
    return (
      <div className={classnames('group', `col-${groupConfig.length}`)} key={groupConfig[0].code}>
        {groupConfig.map((item) => {
          return genItem(item);
        })}
      </div>
    );
  };

  return (
    <div className={getClassName('CrudListForm')}>
      <div className={classnames('wrapper', mode)}>
        {config.map((obj) => {
          return getType(obj) === 'Array' ? genGroup(obj as TypeField[]) : genItem(obj as TypeField);
        })}

        {showSubmit ? (
          <div className="item submit">
            <div className="name"></div>
            <div className="value">
              <Button type="primary" onClick={(e) => onSubmit(formValue)}>
                提交
              </Button>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
