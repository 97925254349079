import React from 'react';
import ReactDOM from 'react-dom/client';
import { Index } from './components/Index';
import { PageFlow } from './components/PageFlow';
import { CrudListForm } from './components/CrudListForm';

const ROOT_DOM_ID = 'app-flow-web';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.FLOW_WEB = {
  PageFlow,
  CrudListForm,
  React,
  ReactDOM,
  render: (component: any, dom: HTMLDivElement): void => {
    const root = ReactDOM.createRoot(dom);
    root.render(component);
  },
};

if (document.getElementById(ROOT_DOM_ID)) {
  const root = ReactDOM.createRoot(document.getElementById(ROOT_DOM_ID) as Element);
  root.render(<Index />);
}
