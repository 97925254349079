import React from 'react';
import './index.less';
import { getClassName, noop } from '../Base';
import { getStaticUrl } from '../../api/static';
import classnames from 'classnames';

interface TicketBrandProps {
  /**
   * 颜色主题
   * @default blue
   */
  colorStyle?: 'blue' | 'green';

  /**
   * 标题
   * @default ''
   */
  title?: string;

  /**
   * 订单号
   * @default ''
   */
  ticket?: string;

  /**
   * 标题
   * []
   */
  tags?: string[];

  /**
   * 其它信息列表
   * []
   */
  pairList?: { name: string; value: string }[];

  /**
   * 点击上半部分时的回调
   * noop
   */
  onClick?: () => void;
}

export const TicketBrand = (props: TicketBrandProps): React.ReactElement => {
  const { colorStyle = 'blue', title = '', ticket = '', tags = [], pairList = [], onClick = noop } = props;
  if (!ticket) {
    return (
      <div className={getClassName('TicketBrand')}>
        <div className={classnames('wrapper', colorStyle)}>
          <div className="empty">
            <img src={getStaticUrl('empty.png')} />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className={getClassName('TicketBrand')}>
      <div className={classnames('wrapper', colorStyle)}>
        <div className="top" onClick={onClick}>
          <div className="left">
            <div className="icon">
              <img src={getStaticUrl('boat.svg')} />
            </div>
          </div>
          <div className="right">
            <div className="head">
              {title ? (
                <>
                  <span className="name">{title}</span>
                  <span className="dot">·</span>
                </>
              ) : null}
              <span className="number" title={ticket}>
                {ticket}
              </span>
            </div>
            <div className="label">
              {tags.map((val) => {
                return (
                  <div className="item" key={val} title={val}>
                    {val}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="bottom">
          <div className="inner">
            {pairList.map((obj) => {
              return (
                <div className="item" key={obj.name}>
                  <div className="name">{obj.name}：</div>
                  <div className="value">{obj.value || '--'}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
