import React from 'react';
import './index.less';
import { getClassName, noop } from '../Base';

interface MeasureCardProps {
  /**
   * 标题
   * @default ''
   */
  title?: string;

  /**
   * 数据
   * @default []
   */
  itemList?: { name: string; value: string }[];

  /**
   * 顶条的颜色
   * @default PRIMARY
   */
  color?: string;
}

export const MeasureCard = (props: MeasureCardProps): React.ReactElement => {
  const { title = '', itemList = [], color = '' } = props;
  const main = itemList?.[0];
  const subList = itemList.slice(1);
  return (
    <div className={getClassName('MeasureCard')}>
      <div className="top-bar" style={{ backgroundColor: color }} />
      <div className="wrapper">
        <div className="title">{title}</div>
        <div className="content">
          <div className="main">
            <div className="item">
              <div className="name">{main?.name}</div>
              <div className="value">{main?.value}</div>
            </div>
          </div>
          <div className="sep">
            <div className="inner" />
          </div>
          <div className="sub">
            {subList.map((obj) => {
              return (
                <div className="item" key={obj.name}>
                  <div className="name">{obj.name}</div>
                  <div className="value">{obj.value}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
