import React, { useEffect, useState } from 'react';
import './index.less';
import { getClassName, noop } from '../Base';
import { Header } from '../Header';
import { PageFlow } from '../PageFlow';
import { CrudListForm } from '../CrudListForm';
import { Tooltip, Modal, message, Button, Space } from '../AntD';
import {
  UserCurrentUser,
  AuthLogin,
  CooperationSaveJob,
  DataFormBizOrderAgentPurchase,
  CooperationUpdateTaskStatus,
  CooperationUpdateBillStatus,
  BizOrderBaseInfo,
  DataFormTestForm,
  DataFormBizLadingBill,
  BizOrderOrderCodes,
} from '../../api/resource';
import { TaskList } from './task-list';
import { BillTaskList } from './bill-task-list';
import { BillList } from './bill-list';

interface PageDemoProps {}

export const PageDemo = (props: PageDemoProps): React.ReactElement => {
  const [doesLogin, setDoesLogin] = useState<'yes' | 'no' | 'unknown'>('unknown');
  const [userName, setUserName] = useState('');
  const [eventList, setEventList] = useState<{ name: string; params: Record<string, any> }[]>([]);

  const [refreshKey, setRefreshKey] = useState(0);
  const [modalShow, setModalShow] = useState<Record<string, boolean>>({});

  const [currentOrder, setCurrentOrder] = useState<Record<string, any> | null>(null);
  const [currentBill, setCurrentBill] = useState<Record<string, any> | null>(null);

  const orderBaseInfoReq = BizOrderBaseInfo({ id: currentOrder?.id }).useRequest({
    refreshDeps: [currentOrder, refreshKey],
    ready: !!currentOrder,
  });
  const orderBaseInfo = orderBaseInfoReq.data?.data;

  useEffect(() => {
    UserCurrentUser()
      .setReturnError(true)
      .call()
      .then((response) => {
        if (response.code === '401') {
          setDoesLogin('no');
          show('login');
        } else {
          setDoesLogin('yes');
          setUserName(response.data?.userName);
        }
      });
  }, [doesLogin]);

  const show = (name: string) => {
    setModalShow({
      ...modalShow,
      [name]: true,
    });
  };
  const hide = (name: string) => {
    setModalShow({
      ...modalShow,
      [name]: false,
    });
    setRefreshKey(refreshKey + 1);
  };

  const genLogin = () => {
    if (!modalShow.login) {
      return null;
    }
    return (
      <Modal visible={true} footer={null} onCancel={() => hide('login')}>
        <CrudListForm
          config={[
            { code: 'identifier', name: 'Identifier', type: 'input' },
            { code: 'password', name: 'Password', type: 'password' },
          ]}
          onSubmit={(value) => {
            AuthLogin({ ...value })
              .call()
              .then((response) => {
                message.success('操作成功');
                setDoesLogin('yes');
                hide('login');
                return;
              });
          }}
        />
      </Modal>
    );
  };

  const genCreateTodo = () => {
    if (!modalShow.createTodo) {
      return null;
    }
    return (
      <Modal visible={true} footer={null} onCancel={() => hide('createTodo')}>
        <CrudListForm
          config={[
            { code: 'jobDesc', name: 'JobDesc', type: 'input' },
            {
              code: 'taskInstanceId',
              name: 'TaskId',
              type: (params) => <TaskList orderId={currentOrder?.id} onChange={params.onChange} />,
            },
            {
              code: 'uiType',
              name: '行为',
              type: 'radio',
              params: {
                optionList: [
                  { label: '录入', value: 'new' },
                  { label: '修改', value: 'modify' },
                  { label: '审阅', value: 'review' },
                  { label: '查看详情', value: 'view' },
                  { label: '查看附件', value: 'view_attr' },
                ],
              },
            },
          ]}
          onSubmit={(value) => {
            CooperationSaveJob({
              jobDesc: value.jobDesc,
              status: 'running', // job状态，只有running的才显示
              actionUserCode: 'UP22071111001', // 需要关联的用户code
              actionUserRole: 'purchase_user', // 需要关联的用户角色
              actionName: value.uiType || 'new', // 操作名
              uiType: value.uiType || 'new', //
              uiCode: 'biz_purchase_contract', // 点击操作的时候用到
              taskInstanceId: value.taskInstanceId, // 任务的id，可从5中获取taskList中某一项的 id
            })
              .call()
              .then((response) => {
                message.success('操作成功');
                hide('createTodo');
                return;
              });
          }}
        />
      </Modal>
    );
  };

  const genCreateAgentOrder = () => {
    if (!modalShow.createAgentOrder) {
      return null;
    }
    return (
      <Modal visible={true} footer={null} onCancel={() => hide('createAgentOrder')}>
        <CrudListForm
          config={[]}
          onSubmit={(value) => {
            DataFormBizOrderAgentPurchase({
              orderType: 'agent_purchase',
              purchaseUserCode: 'UP22071111001',
              supplyChainUserCode: 'UC22071111002',
              shipAgentUserCode: 'UF22071110003',
              storeUserCode: 'US22071111004',
              signFreightContractRole: 'supply_chain_user',
              signWarehouseContractRole: 'ship_agent_user',
            })
              .call()
              .then((response) => {

                BizOrderOrderCodes({ orderType: 'agent_purchase' })
                  .call()
                  .then((response) => {
                    const newOrder = response.data?.[0];
                    if (newOrder) {
                      setCurrentOrder(newOrder);
                    }
                  });

                const id = response.data;
                message.success('操作成功, ID 是 ' + id);
                hide('createAgentOrder');
                return;
              });
          }}
        />
      </Modal>
    );
  };

  const genCreateSelfOrder = () => {
    if (!modalShow.createSelfOrder) {
      return null;
    }
    return (
      <Modal visible={true} footer={null} onCancel={() => hide('createSelfOrder')}>
        <CrudListForm
          config={[]}
          onSubmit={(value) => {
            DataFormBizOrderAgentPurchase({
              orderType: 'self_purchase',
              purchaseUserCode: 'UP22071111001',
              shipAgentUserCode: 'UF22071110003',
              storeUserCode: 'US22071111004',
              signWarehouseContractRole: 'ship_agent_user',
            })
              .call()
              .then((response) => {

                BizOrderOrderCodes({ orderType: 'self_purchase' })
                  .call()
                  .then((response) => {
                    const newOrder = response.data?.[0];
                    if (newOrder) {
                      setCurrentOrder(newOrder);
                    }
                  });

                const id = response.data;
                message.success('操作成功, ID 是 ' + id);
                hide('createSelfOrder');
                return;
              });
          }}
        />
      </Modal>
    );
  };

  const genUpdateTask = () => {
    if (!modalShow.updateTask) {
      return null;
    }
    return (
      <Modal visible={true} footer={null} onCancel={() => hide('updateTask')}>
        <CrudListForm
          config={[
            {
              code: 'id',
              name: 'TaskID',
              type: (params) => <TaskList orderId={currentOrder?.id} onChange={params.onChange} />,
            },
            {
              code: 'status',
              name: '状态',
              type: 'radio',
              params: {
                optionList: [
                  { label: 'init', value: 'init' },
                  { label: 'running', value: 'running' },
                  { label: 'finish', value: 'finish' },
                ],
              },
            },
          ]}
          onSubmit={(value) => {
            CooperationUpdateTaskStatus({ id: value.id, status: value.status || 'finish' })
              .call()
              .then((response) => {
                message.success('操作成功');
                hide('updateTask');
                return;
              });
          }}
        />
      </Modal>
    );
  };

  const genCreateContract = () => {
    if (!modalShow.createContract) {
      return null;
    }
    return (
      <Modal visible={true} footer={null} onCancel={() => hide('createContract')}>
        <CrudListForm
          config={[{ code: 'itemName', name: '物品名称', type: 'input' }]}
          onSubmit={(value) => {
            if (!currentOrder) {
              return;
            }
            const stamp = new Date().getTime().toString().slice(3);
            const p = {
              orderCode: currentOrder.orderCode,
              purchaseContractCode: `HT${stamp}`,
              orderType: currentOrder.orderType,
              contractStatus: 'waitReview',
              itemName: value.itemName || '-',
              itemFiles: '{"files":[],"filesDeleted":[]}',
              physical: 'KLZ',
              itemQuantity: '5000',
              itemUnit: 'DUN',
              packageUnit: 'CN',
              shipType: 'container',
              dangerCat: 'BZP',
              tradeWay: 'FOB',
              tradeAmount: '1000',
              currency: 'USD',
              payWay: 'LCY',
              purchaseContractFiles: '{"files":[],"filesDeleted":[]}',
            };
            DataFormTestForm(p)
              .call()
              .then((response) => {
                message.success('操作成功');
                hide('createContract');
                return;
              });
          }}
        />
      </Modal>
    );
  };

  const genCreateBill = () => {
    if (!modalShow.createBill) {
      return null;
    }
    return (
      <Modal visible={true} footer={null} onCancel={() => hide('createBill')}>
        <CrudListForm
          config={[
            {
              code: 'shipType',
              name: '类型',
              type: 'radio',
              params: {
                optionList: [
                  { label: '集装箱', value: 'container' },
                  { label: '散货', value: 'bulkload' },
                ],
              },
            },
            { code: 'itemName', name: '物品名称', type: 'input' },
          ]}
          onSubmit={(value) => {
            if (!currentOrder) {
              return;
            }
            const stamp = new Date().getTime().toString().slice(3);
            const p = {
              billCode: `SOCAL${stamp}`,
              bizOrderCode: currentOrder.orderCode,
              shipType: value.shipType || 'container',
              shipper: '测试_货运代理商',
              consignee: '测试_供应链集成商',
              demand: '测试_供应链集成商',
              shipName: 'shipA',
              shipMmsi: '271001092',
              loadPort: 'XIAMEN',
              gmtLoad: '2022-07-27 00:00:01',
              gmtShip: '2022-07-28 00:00:01',
              unloadPort: 'LIANYUNGANG',
              noticeAddress: 'nnnn',
              itemName: value.itemName || '-',
              itemQuantity: '5000.0',
              itemUnit: 'DUN',
              containerType: '20BT',
              attachedFiles:
                '{"files":[{"fileName":"1658940171319.jpg","fileURL":"http://yanquntest-ossfilestore.sg-chain.com/20220728-110233-4807f81a-81d4-4291-b8fc-c33d2f836eae?Expires=1659337353&OSSAccessKeyId=LTAI5tLHTy5uR8vA7RDVougB&Signature=JPE0qKxLrf%2F9xwBxUruf7ovMi0I%3D","imgURL":null}],"filesDeleted":[]}',
            };
            DataFormBizLadingBill(p)
              .call()
              .then((response) => {
                message.success('操作成功');
                hide('createBill');
                return;
              });
          }}
        />
      </Modal>
    );
  };

  const genUpdateBill = () => {
    if (!modalShow.updateBill) {
      return null;
    }
    return (
      <Modal visible={true} footer={null} onCancel={() => hide('updateBill')}>
        <CrudListForm
          config={[
            {
              code: 'id',
              name: 'TaskID',
              type: (params) => <BillList orderId={currentOrder?.id} onChange={params.onChange} />,
            },
            {
              code: 'status',
              name: '状态',
              type: 'radio',
              params: {
                optionList: [
                  { label: 'init', value: 'init' },
                  { label: 'running', value: 'running' },
                  { label: 'finish', value: 'finish' },
                ],
              },
            },
          ]}
          onSubmit={(value) => {
            CooperationUpdateBillStatus({ id: value.id, status: value.status || 'finish' })
              .call()
              .then((response) => {
                message.success('操作成功');
                hide('updateBill');
                return;
              });
          }}
        />
      </Modal>
    );
  };

  const genUpdateBillTask = () => {
    if (!modalShow.updateBillTask) {
      return null;
    }
    return (
      <Modal visible={true} footer={null} onCancel={() => hide('updateBillTask')}>
        <CrudListForm
          config={[
            {
              code: 'id',
              name: 'TaskID',
              type: (params) => <BillTaskList billId={currentBill?.bizId} onChange={params.onChange} />,
            },
            {
              code: 'status',
              name: '状态',
              type: 'radio',
              params: {
                optionList: [
                  { label: 'init', value: 'init' },
                  { label: 'running', value: 'running' },
                  { label: 'finish', value: 'finish' },
                ],
              },
            },
          ]}
          onSubmit={(value) => {
            CooperationUpdateTaskStatus({ id: value.id, status: value.status || 'finish' })
              .call()
              .then((response) => {
                message.success('操作成功');
                hide('updateBillTask');
                return;
              });
          }}
        />
      </Modal>
    );
  };

  const onEvent = (event: string, params: Record<string, any>) => {
    setEventList([{ name: event, params }, ...eventList]);
    if (event === 'CREATE_BIZ_ORDER') {
      if (params.type === 'agent') {
        show('createAgentOrder');
        return;
      }
      if (params.type === 'self') {
        show('createSelfOrder');
        return;
      }
    }
    if (event === 'SELECT_ORDER') {
      setCurrentOrder(params.order);
      setCurrentBill(null);
      return;
    }
    if (event === 'SELECT_BILL') {
      setCurrentBill(params.bill);
      return;
    }
    if (event === 'NEW_BILL') {
      show('createBill');
      return;
    }
  };

  return (
    <div className={getClassName('PageDemo')}>
      {genLogin()}
      {genCreateTodo()}
      {genCreateAgentOrder()}
      {genCreateSelfOrder()}
      {genUpdateTask()}
      {genUpdateBill()}
      {genUpdateBillTask()}
      {genCreateContract()}
      {genCreateBill()}
      <div className="wrapper">
        <div className="header">
          <Header name={userName} />
        </div>
        <div className="toolbar">
          <div className="inner">
            <Space>
              <span>
                <a
                  href="https://dafuture.yuque.com/docs/share/1d3aa9c6-824c-4967-9033-76946e9dec79"
                  target="_blank"
                >
                  文档
                </a>
              </span>
              <Button onClick={() => show('login')}>登录</Button>
              <Button onClick={() => show('createTodo')} disabled={!currentOrder}>
                创建待办
              </Button>
              <Button onClick={() => show('updateTask')} disabled={!currentOrder}>
                修改 Task 状态
              </Button>
              <Button
                onClick={() => show('createContract')}
                disabled={orderBaseInfo ? orderBaseInfo.contractUpload : true}
              >
                添加合同
              </Button>
              <Button
                onClick={() => show('createBill')}
                disabled={orderBaseInfo ? !orderBaseInfo.contractUpload : true}
              >
                创建提单
              </Button>
              <Button onClick={() => show('updateBill')} disabled={!currentOrder}>
                修改提单本身状态
              </Button>
              <Button onClick={() => show('updateBillTask')} disabled={!currentBill}>
                修改提单 Task 状态
              </Button>
            </Space>
          </div>
        </div>
        {doesLogin === 'yes' ? (
          <div className="content">
            <div className="left">
              <PageFlow
                orderType={
                  currentOrder ? (currentOrder.orderType === 'agent_purchase' ? 'agent' : 'self') : undefined
                }
                orderId={currentOrder?.id}
                billId={currentBill?.bizId}
                onEvent={onEvent}
                orderRefreshKey={refreshKey.toString()}
                todoRefreshKey={refreshKey.toString()}
                subProcessRefreshKey={refreshKey.toString()}
                processRefreshKey={refreshKey.toString()}
                todoRefreshAutoInterval={5000}
              />
            </div>
            <div className="right">
              <div className="title">PageFlow 组件的事件</div>
              <div className="content">
                {eventList.map((obj, idx) => {
                  return (
                    <div className="item" key={obj.name + idx.toString()}>
                      <div className="name">事件名：{obj.name}</div>
                      <div className="value">
                        <span>参数：</span>
                        {Object.keys(obj.params).map((key) => {
                          return (
                            <span className="param" key={key}>
                              <Tooltip title={JSON.stringify(obj.params[key])}>{key}</Tooltip>
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
