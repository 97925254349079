import React from 'react';
import './index.less';
import { getClassName, noop } from '../Base';
import { getStaticUrl } from '../../api/static';

interface SearchProps {
  /**
   * placeholder
   * @default 输入关键字搜索
   */
  placeholder?: string;

  /**
   * 内容变化时的回调
   * @default noop
   */
  onChange?: (val: string) => void;
}

export const Search = (props: SearchProps): React.ReactElement => {
  const { placeholder = '输入关键字搜索', onChange = noop } = props;
  return (
    <div className={getClassName('Search')}>
      <div className="wrapper">
        <div className="inner">
          <input type="text" placeholder={placeholder} onChange={(e) => onChange(e.target.value)} />
        </div>
        <div className="icon">
          <img src={getStaticUrl('glass.svg')} />
        </div>
      </div>
    </div>
  );
};
