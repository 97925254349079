import React, { useState } from 'react';
import { noop } from '../Base';
import { CooperationProcess } from '../../api/resource';
import { Select } from '../AntD';
const Option = Select.Option;

export const BillTaskList = (props: {
  billId?: number;
  onChange?: (id: number) => void;
}): React.ReactElement => {
  const { billId, onChange = noop } = props;
  const taskReq = CooperationProcess({ bizId: billId, bizType: 'lading_bill' }).useRequest({
    ready: !!billId,
  });
  const nodeList = taskReq.data?.data?.nodeList || [];
  const itemList: { name: string; value: number }[] = [];
  nodeList.forEach((node) => {
    (node.taskList || []).forEach((task) => {
      itemList.push({ value: task.id, name: `${node.nodeName} / ${task.taskName}` });
    });
  });
  return (
    <Select style={{ width: 300 }} onChange={onChange}>
      {itemList.map((obj) => {
        return (
          <Option value={obj.value} key={obj.value}>
            {obj.name}
          </Option>
        );
      })}
    </Select>
  );
};
