import axios, { AxiosRequestConfig, Method, ResponseType, AxiosResponse } from 'axios';
import { useRequest } from 'ahooks';
import type { Options } from 'ahooks/lib/useRequest/src/types';
import { Config } from '../config';
import { message } from '../../components/AntD';

const CORRECT_CODE = '0000';

export type ReturnDataType<T = any> = { code: string; msg: string | null; data: T };
export type ResourceResponseType<T = any> =
  | AxiosResponse<ReturnDataType<T>>
  | { data: ReturnDataType<T>; status: number };

class Request<TParams, TResponseData> {
  name: string;
  action: string;
  params: Record<string, any>;
  method: Method = 'GET';
  responseType: ResponseType = 'json';
  returnError: boolean;
  prefix: string;

  constructor(
    name: string,
    action: string,
    params: Record<string, any> = {},
    method: Method = 'GET',
    responseType: ResponseType = 'json',
  ) {
    this.name = name;
    this.action = action;
    this.params = params;
    this.method = method;
    this.responseType = responseType;
    this.returnError = false;
    this.prefix = Config.getUrlPrefix();
  }

  setReturnError(flag: boolean) {
    this.returnError = flag;
    return this;
  }

  getAxiosParams(params: Record<string, any> = {}): AxiosRequestConfig {
    const { prefix, name, action, method, responseType } = this;
    const pre = name.includes('http://') || name.includes('https://') ? '' : prefix;
    const url = `${pre}${pre ? '/' : ''}${name}${action ? '/' : ''}${action || ''}`;
    const axiosParams: AxiosRequestConfig = {
      url,
      method,
      responseType,
      withCredentials: true,
    };

    axiosParams.headers = {};
    const mergeParams: Record<string, any> = {
      ...this.params,
      ...params,
      __formToken__: '' + (new Date()).getTime() + Math.random()
    };

    if (method.toUpperCase() === 'GET') {
      const p = new URLSearchParams();
      Object.keys(mergeParams).forEach((key) => {
        p.append(key, mergeParams[key]);
      });
      axiosParams.params = p;
    } else {
      axiosParams.data = mergeParams;
    }
    return axiosParams;
  }

  originCall(params?: Record<string, any> | undefined): Promise<ResourceResponseType<TResponseData | null>> {
    const axiosParams = this.getAxiosParams();

    if (params) {
      if (axiosParams.method === 'GET') {
        axiosParams.params = params;
      } else {
        axiosParams.data = params;
      }
    }

    return new Promise<ResourceResponseType<TResponseData | null>>((resolve) => {
      axios
        .request(axiosParams)
        .then((response) => {
          resolve(response);
        })
        .catch((e) => {
          const response: AxiosResponse = e.response;
          const code = e.response?.status || -1;
          resolve({
            status: 200,
            data: {
              code: code.toString(),
              msg: e.message,
              data: null,
            },
          });
        });
    });
  }

  call(params?: Record<string, any>): Promise<ReturnDataType<TResponseData | null>> {
    const axiosParams = this.getAxiosParams();

    if (params) {
      if (axiosParams.method === 'GET') {
        axiosParams.params = params;
      } else {
        axiosParams.data = params;
      }
    }

    return new Promise<ReturnDataType<TResponseData | null>>((resolve) => {
      axios
        .request(axiosParams)
        .then((response) => {
          if (response.data.code !== CORRECT_CODE && !this.returnError) {
            message.error(response.data.msg);
            return;
          }
          resolve(response.data);
        })
        .catch((e) => {
          const response: AxiosResponse = e.response;
          const code = e.response?.status || -1;
          if (!this.returnError) {
            message.error(e.message);
            return;
          }

          resolve({
            code: code.toString(),
            msg: e.message,
            data: null,
          });
        });
    });
  }

  useRequest(options?: Options<ReturnDataType<TResponseData | null>, [TParams]>) {
    const callback = this.call.bind(this);
    return useRequest<ReturnDataType<TResponseData | null>, [TParams]>(callback, options);
  }
}

function genRequest<TResponseData, TParams = undefined>(
  name: string,
  action: string,
  method?: Method,
): (params?: Record<string, any>) => Request<TParams, TResponseData> {
  return (params: Record<string, any> = {}) => {
    const p = {
      //userCode: 'User001',
      ...params,
    };
    return new Request<TParams, TResponseData>(name, action, p, method || 'GET');
  };
}

export const CooperationJobList = genRequest<
  { bizCode: string; jobDesc: string; actionTitle: string; actionName: string; id: number }[]
>('cooperation', 'jobList');
export const BizOrderOrderCodes = genRequest<
  { orderType: string; orderCode: string; id: number }[],
  { orderType: 'self_purchase' | 'agent_purchase' }
>('bizOrder', 'orderCodes');
export const BizOrderBaseInfo = genRequest<
  {
    orderTypeDesc: string;
    contractCode: string;
    contractUpload: boolean;
    tags: string[];
    fields: { name: string; value: string }[];
  },
  { orderCode: string }
>('bizOrder', 'baseInfo');

type TypeTaskAction = {
  name: string;
  title: string;
  uiType: string;
  uiCode: string;
  transferParams: Record<string, any>;
};
type TypeTask = {
  id: number;
  bizId?: number;
  taskCode: string;
  taskInstanceId: number;
  leftIndent: string;
  taskName: string;
  status: 'init' | 'running' | 'finish';
  taskContentStatus: 'init' | 'running' | 'finish' | 'wait-upload' | 'wait-review';
  actionList: TypeTaskAction[] | null;
};
type TypeNode = {
  id: number;
  name?: string;
  title?: string;
  nodeCode?: string;
  nodeName?: string;
  status: 'init' | 'running' | 'finish';
  arrowType: 'solid' | 'dotted' | 'none';
  arrowDirection: 'before' | 'after' | 'none';
  taskList: TypeTask[];
};
type TypeProcess = {
  bizCode: string;
  processInstanceId: number;
  processCode: string;
  nodeList: TypeNode[];
};
export const CooperationProcess = genRequest<TypeProcess, { bizId: number; bizType: string }>(
  'cooperation',
  'process',
);

type TypeWholeStatus = {
  onTheWay: number;
  finished: number;
  sum: number;
};
export const BizOrderWholeStatus = genRequest<{
  agent_purchase: TypeWholeStatus;
  self_purchase: TypeWholeStatus;
}>('bizOrder', 'wholeStatus');

export const UserCurrentUser = genRequest<Record<string, any>, null>('user', 'currentUser');
export const AuthLogin = genRequest<any, { identifier: string; password: string }>('auth', 'login', 'POST');

export const CooperationSaveJob = genRequest<any, Record<string, string | number>>(
  'cooperation',
  'saveJob',
  'POST',
);

export const DataFormBizOrderAgentPurchase = genRequest<any, Record<string, string | number>>(
  'data',
  'form/biz_order_agent_purchase',
  'POST',
);
export const CooperationUpdateTaskStatus = genRequest<any, { id: number; status: string }>(
  'cooperation',
  'updateTaskStatus',
);
export const CooperationUpdateBillStatus = genRequest<any, { id: number; status: string }>(
  'cooperation',
  'updateBillStatus',
);

export const DataFormTestForm = genRequest<any, Record<string, string>>('data', 'form/test_form', 'POST');
export const DataFormBizLadingBill = genRequest<any, Record<string, string>>(
  'data',
  'form/biz_lading_bill',
  'POST',
);
export const BizOrderAddRights = genRequest<any, { orderType: 'self_purchase' | 'agent_purchase' }>(
  'bizOrder',
  'addRights',
  'GET',
);
export const CooperationBtns = genRequest<any, null>('cooperation', 'btns', 'GET');
