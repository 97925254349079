import React from 'react';
import './index.less';
import { getClassName, noop } from '../Base';
import { getStaticUrl } from '../../api/static';
import { Select } from '../AntD';
import classnames from 'classnames';
const Option = Select.Option;

interface ToolbarProps {
  /**
   * 单号列表
   * @default []
   */
  ticketList?: { ticket: string; type: string; id: number }[];

  /**
   * 类型
   * @default 'proxy'
   */
  type?: 'agent' | 'self';

  /**
   * 当前 ticket
   * @default ''
   */
  currentTicket?: string;

  /**
   * 切换类型时的回调
   * @default noop
   */
  onType?: (type: 'agent' | 'self') => void;

  /**
   * 切换时的回调
   * @default noop
   */
  onTicket?: (ticket: { ticket: string; type: string; id: number }) => void;

  /**
   * 新增提单回调
   * @default noop
   */
  onCreate?: () => void;

  /**
   * 是否隐藏添加按钮
   * @default false
   */
  hideButton?: boolean;
}

export const Toolbar = (props: ToolbarProps): React.ReactElement => {
  const {
    ticketList = [],
    type = 'agent',
    currentTicket = '',
    hideButton = false,
    onType = noop,
    onTicket = noop,
    onCreate = noop,
  } = props;

  const onTicketChange = (ticket: string) => {
    const obj = ticketList.find((obj) => obj.ticket === ticket);
    if (obj) {
      onTicket(obj);
    }
  };

  return (
    <div className={getClassName('Toolbar')}>
      <div className="wrapper">
        <div className="btn-group">
          <div
            className={classnames('btn left', (type === 'agent').toString())}
            onClick={() => onType('agent')}
          >
            代理采购
          </div>
          <div
            className={classnames('btn right', (type === 'self').toString())}
            onClick={() => onType('self')}
          >
            自主采购
          </div>
        </div>
        <div className="ticket">
          <div className="label">业务单号</div>
          <div className="select">
            <Select style={{ width: 255 }} showSearch={true} onChange={onTicketChange} value={currentTicket}>
              {ticketList.map((obj) => {
                return (
                  <Option value={obj.ticket} key={obj.ticket}>
                    {obj.ticket}
                  </Option>
                );
              })}
            </Select>
          </div>
        </div>
        {hideButton ? null : (
          <div className="new">
            <div className="btn">
              <div className="icon">
                <img src={getStaticUrl('add-white.svg')} />
              </div>
              <div className="text" onClick={onCreate}>
                新增业务单
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
