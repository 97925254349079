import React, { useState } from 'react';
import './index.less';
import { getClassName, noop } from '../Base';
import { getStaticUrl } from '../../api/static';
import classnames from 'classnames';
import { Empty, Pagination, Tooltip } from '../AntD';

export type TypeToDo = {
  id?: number;
  ticket: string;
  title: string;
  actionName: string;
  ignore?: boolean;
};

interface MyTodoProps {
  /**
   * 数据
   * @default []
   */
  itemList?: TypeToDo[];

  /**
   * 列数
   * @default 2
   */
  columnNumber?: number;

  /**
   * 点击时的回调
   * @default noop
   */
  onItem?: (item: TypeToDo) => void;
}

export const MyTodoV2 = (props: MyTodoProps): React.ReactElement => {
  const { itemList = [], columnNumber = 2, onItem = noop } = props;
  const PAGE_SIZE = 12;
  const [page, setPage] = useState(1);

  const groupList: TypeToDo[][] = new Array(columnNumber).fill(0).map(() => []);

  (() => {
    let idx = 0;
    const copyItemList = itemList.slice(PAGE_SIZE * (page - 1), PAGE_SIZE * page);
    // 填充满 PAGE_SIZE 个，把页面衬起来
    const appendCount = PAGE_SIZE - copyItemList.length;
    Array(appendCount)
      .fill(null)
      .forEach((o, i) => {
        copyItemList.push({ ticket: `append-${i}`, title: '-', ignore: true, actionName: '' });
      });
    while (true) {
      if (!copyItemList?.length) {
        break;
      }
      const obj = copyItemList.shift();
      if (obj) {
        groupList[idx % columnNumber].push(obj);
      }
      idx += 1;
    }
  })();

  const genMain = () => {
    return (
      <>
        {groupList.map((group, idx) => {
          return (
            <div className="column" key={idx}>
              {group.map((todo, j) => {
                return (
                  <div
                    className={classnames('item', todo.ignore ? 'hidden' : '')}
                    onClick={() => onItem(todo)}
                    key={j + todo.ticket + todo.title + todo.id}
                  >
                    <Tooltip title="点击处理">
                      <div className="right">
                        <div className="btn">{todo.actionName}</div>
                      </div>
                      <div className="left">
                        <span className="ticket">{todo.ticket}</span>
                        <span className="title">{todo.title}</span>
                      </div>
                    </Tooltip>
                  </div>
                );
              })}
            </div>
          );
        })}
      </>
    );
  };
  const genContent = () => {
    return (
      <div className="content">
        {itemList.length === 0 ? (
          <div className="empty">
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
        ) : (
          genMain()
        )}
      </div>
    );
  };

  const genFooter = () => {
    return (
      <div className="footer">
        <Pagination
          size="small"
          current={page}
          pageSize={PAGE_SIZE}
          total={itemList.length}
          showSizeChanger={false}
          onChange={(page, pageSize) => {
            setPage(page);
          }}
        />
      </div>
    );
  };

  return (
    <div className={getClassName('MyTodoV2')}>
      <div className="wrapper">
        <div className="top-bar" />
        <div className="header">
          <div className="title">
            <div className="icon">
              <img src={getStaticUrl('clock.svg')} />
            </div>
            <div className="text">
              <span className="text">我的待办</span>
              {itemList?.length ? <span className="number">({itemList.length})</span> : null}
            </div>
          </div>
        </div>
        {genContent()}
        {genFooter()}
      </div>
    </div>
  );
};
