import React from 'react';
import './index.less';
import { getClassName, noop } from '../Base';
import { getStaticUrl } from '../../api/static';
import classnames from 'classnames';

export interface TicketNodeProps {
  /**
   * 颜色样式
   * @default blue
   */
  colorStyle?: 'blue' | 'green' | 'gray' | 'blue-process' | 'green-process';

  /**
   * 标题
   * @default ''
   */
  title?: string;

  /**
   * 条目内容
   * @default []
   */
  itemList?: {
    iconStyle?: 'green' | 'gray' | 'process' | 'wait-upload' | 'wait-review';
    textStyle?: 'normal' | 'gray';
    itemStyle?: 'normal' | 'selected';
    actionList?: string[];
    text: string;
    indent?: number;
    origin?: Record<string, any>;
  }[];

  /**
   * 事件回调内容
   * @default noop
   */
  onEvent?: (
    event: 'TASK-VIEW' | 'TASK-NEW' | 'TASK-MODIFY' | 'TASK-REVIEW' | 'TASK-VIEWATTR' | 'TASK-ADD',
    params?: Record<string, any>,
  ) => void;

  /**
   * 节点高度
   * @default 220px
   */
  height?: string;
}

export const TicketNode = (props: TicketNodeProps): React.ReactElement => {
  const { colorStyle = 'blue', title = '', itemList = [], onEvent = noop, height = '220px' } = props;
  const nodeStyle = { height };
  return (
    <div className={classnames(getClassName('TicketNode'), colorStyle)} style={nodeStyle}>
      <div className="head" title={title}>
        {title}
      </div>
      <div className="content">
        <div className="inner">
          {itemList.map((obj) => {
            const style: Record<string, any> = {};
            if (obj.indent) {
              style.paddingLeft = obj.indent + 12;
            }
            return (
              <div
                className={classnames('item', obj.textStyle)}
                key={obj?.origin?.id || obj.text}
                style={style}
              >
                <div className={classnames('icon', obj.iconStyle || 'green')}>
                  <img src={getStaticUrl('finish.svg')} className="green" />
                  <img src={getStaticUrl('todo.svg')} className="gray" />
                  <img src={getStaticUrl('process.svg')} className="process" />
                  <img src={getStaticUrl('wait-upload.png')} className="wait-upload" />
                  <img src={getStaticUrl('wait-review.png')} className="wait-review" />
                </div>
                <div className="action">
                  {(obj.actionList || []).map((action) => {
                    if (action === '$VIEW') {
                      return (
                        <span
                          title="查看详情"
                          key={action}
                          className="icon"
                          onClick={() => onEvent('TASK-VIEW', { task: obj })}
                        >
                          <img src={getStaticUrl('view.svg')} />
                        </span>
                      );
                    }
                    if (action === '$VIEWATTR') {
                      return (
                        <span
                          title="查看附件"
                          key={action}
                          className="icon"
                          onClick={() => onEvent('TASK-VIEWATTR', { task: obj })}
                        >
                          <img src={getStaticUrl('viewattr.svg')} />
                        </span>
                      );
                    }
                    if (action === '$MODIFY') {
                      return (
                        <span
                          title="修改"
                          key={action}
                          className="icon"
                          onClick={() => onEvent('TASK-MODIFY', { task: obj })}
                        >
                          <img src={getStaticUrl('modify.svg')} />
                        </span>
                      );
                    }
                    if (action === '$REVIEW') {
                      return (
                        <span
                          title="审阅"
                          key={action}
                          className="icon"
                          onClick={() => onEvent('TASK-REVIEW', { task: obj })}
                        >
                          <img src={getStaticUrl('review.svg')} />
                        </span>
                      );
                    }
                    if (action === '$NEW') {
                      return (
                        <span
                          title="录入"
                          key={action}
                          className="icon"
                          onClick={() => onEvent('TASK-NEW', { task: obj })}
                        >
                          <img src={getStaticUrl('new.svg')} />
                        </span>
                      );
                    }
                    if (action === '$ADD') {
                      return (
                        <span
                          title="新增补充协议"
                          key={action}
                          className="icon"
                          onClick={() => onEvent('TASK-ADD', { task: obj })}
                        >
                          <img src={getStaticUrl('add-primary.svg')} />
                        </span>
                      );
                    }

                    return (
                      <span className="btn" key={action}>
                        {action}
                      </span>
                    );
                  })}
                </div>
                <div
                  className={classnames('text', 'action-list-' + (obj.actionList || []).length.toString())}
                  title={obj.text}
                >
                  {obj.text}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
