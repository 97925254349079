import React, { useEffect } from 'react';
import './index.less';
import { getClassName, noop } from '../Base';
import { TicketNodePrimary, TicketNodePrimaryProps } from '../TicketNodePrimary';
import { TicketNode, TicketNodeProps } from '../TicketNode';
import { TicketArrow } from '../TicketArrow';

type TypeLayoutResult = {
  perRow: number;
  finalFullWidth: number;
  nodeWidth: number;
  arrowWidth: number;
  layout: string[][];
};

const calcLayout = (params: {
  fullWidth: number;
  arrowWidth: number;
  nodeMinWidth: number;
  nodeMaxWidth: number;
  nodeCount: number;
  mustOneRow: boolean;
}): TypeLayoutResult => {
  const { fullWidth, arrowWidth, nodeMinWidth, nodeMaxWidth, nodeCount, mustOneRow } = params;
  let [perRow, nodeWidth] = (() => {
    if (fullWidth === 0) {
      return [nodeCount, nodeMinWidth];
    }
    let result = nodeCount;
    let nodeWidth = 0;
    while (true) {
      if (result === 0 || result === 1) {
        return [result, nodeMinWidth];
      }
      const nodeAllWidth = fullWidth - (result - 1) * arrowWidth;
      nodeWidth = nodeAllWidth / result;

      if (result === nodeCount) {
        if (nodeWidth >= nodeMaxWidth) {
          return [result, nodeMaxWidth];
        }
      }

      if (nodeWidth < nodeMinWidth) {
        if (result === 2) {
          return [result, nodeMinWidth];
        }
      }

      if (nodeWidth <= nodeMaxWidth && nodeWidth >= nodeMinWidth) {
        if (nodeCount % result !== 1) {
          return [result, nodeWidth];
        }
      }

      if (result === 2) {
        return [Math.max(Math.floor(fullWidth / nodeMinWidth), 2), nodeMinWidth];
      }

      result -= 1;
    }
  })();

  const rows = Math.ceil(nodeCount / perRow);
  if (mustOneRow && rows !== 1) {
    perRow = nodeCount;
    nodeWidth = nodeMinWidth;
  }

  const finalFullWidth = (perRow - 1) * arrowWidth + nodeWidth * perRow;

  const layout: number[][] = [];
  let row = [];
  let left = nodeCount;
  while (true) {
    if (left === 0) {
      break;
    }
    row.push(nodeCount - left + 1);
    left -= 1;
    if (row.length === perRow) {
      layout.push(row);
      row = [];
    }
  }
  if (row.length) {
    layout.push(row);
  }

  const layoutStr = layout.map((group, i) => {
    while (group.length !== perRow) {
      group.push(0);
    }
    if (i % 2 === 1) {
      group.reverse();
      return group.join('-');
    }

    return group.join('-');
  });

  const layoutResult: string[][] = [];
  layoutStr.forEach((group, idx) => {
    const segList = group.split('-');
    const newGroup = [];
    for (let i = 0, l = segList.length; i < l; i++) {
      const current = segList[i];
      const next = segList[i + 1];
      if (!next) {
        newGroup.push(current);
        break;
      }

      let link = idx % 2 === 0 ? '>' : '<';
      newGroup.push(current);
      if (idx % 2 === 0) {
        if (next === '0') {
          link = '-';
        }
      } else {
        if (current === '0') {
          link = '-';
        }
      }
      newGroup.push(link);
    }
    layoutResult.push(newGroup);
  });

  return { perRow, finalFullWidth, nodeWidth, arrowWidth, layout: layoutResult };
};

export type TypeTicketLayoutNode = {
  type: 'node' | 'primary';
  props: TicketNodePrimaryProps | TicketNodeProps;
  id: string;
};

export interface TicketLayoutProps {
  fullWidth: number;
  nodeMinWidth: number;
  nodeMaxWidth: number;
  arrowWidth: number;
  itemList: TypeTicketLayoutNode[];
  arrowStyleMap?: Record<string, 'green' | 'blue' | 'gray' | 'weak'>;
  mustOneRow?: boolean;
  onLayout?: (layout: TypeLayoutResult) => void;
  onEvent?: (node: TypeTicketLayoutNode, event: string, params?: Record<string, any>) => void;
}

export const TicketLayout = (props: TicketLayoutProps): React.ReactElement => {
  const {
    fullWidth,
    nodeMinWidth,
    nodeMaxWidth,
    arrowWidth,
    itemList = [],
    arrowStyleMap = {},
    mustOneRow = false,
    onLayout = noop,
    onEvent = noop,
  } = props;

  const layout = calcLayout({
    fullWidth,
    nodeMaxWidth,
    nodeMinWidth,
    arrowWidth,
    nodeCount: itemList.length,
    mustOneRow,
  });

  useEffect(() => {
    onLayout(layout);
  }, [layout]);

  const onInnerEvent = (node: typeof itemList[0]) => {
    return (
      event:
        | 'NEW_BILL'
        | 'ITEM'
        | 'TASK-NEW'
        | 'TASK-MODIFY'
        | 'TASK-REVIEW'
        | 'TASK-VIEW'
        | 'TASK-VIEWATTR'
        | 'TASK-ADD',
      params?: Record<string, any>,
    ) => {
      onEvent(node, event, params);
    };
  };

  const genRow = (row: string[]) => {
    const result: React.ReactElement[] = [];
    for (let i = 0, l = row.length; i < l; i++) {
      const col = row[i];
      if (col === '>') {
        const arrowKey = [row[i - 1] || '', row[i + 1] || ''].join('-');
        result.push(
          <div className="item arrow" style={{ width: layout.arrowWidth }} key={`${col}-${i}`}>
            <TicketArrow colorStyle={arrowStyleMap[arrowKey]} />
          </div>,
        );
        continue;
      }
      if (col === '<') {
        const arrowKey = [row[i + 1] || '', row[i - 1] || ''].join('-');
        result.push(
          <div className="item arrow" style={{ width: layout.arrowWidth }} key={`${col}-${i}`}>
            <TicketArrow inverse={true} colorStyle={arrowStyleMap[arrowKey]} />
          </div>,
        );
        continue;
      }
      if (col === '-') {
        result.push(
          <div className="item arrow hidden" style={{ width: layout.arrowWidth }} key={`${col}-${i}`}>
            <TicketArrow />
          </div>,
        );
        continue;
      }
      if (col === '0') {
        result.push(
          <div className="item node hidden" style={{ width: layout.nodeWidth }} key={`${col}-${i}`}>
            <TicketNodePrimary />
          </div>,
        );
        continue;
      }
      const obj = itemList[Number(col) - 1];
      result.push(
        <div className="item node" style={{ width: layout.nodeWidth }} key={obj.id}>
          {obj.type === 'primary' ? (
            <TicketNodePrimary {...obj.props} onEvent={onInnerEvent(obj)} />
          ) : (
            <TicketNode {...obj.props} onEvent={onInnerEvent(obj)} />
          )}
        </div>,
      );
    }
    return result;
  };

  return (
    <div className={getClassName('TicketLayout')}>
      <div className="wrapper">
        {layout.layout.map((group) => {
          return (
            <div className="row" key={group.join('-')}>
              {genRow(group)}
            </div>
          );
        })}
      </div>
    </div>
  );
};
