import React from 'react';
import './index.less';
import { getClassName, noop } from '../Base';
import { getStaticUrl } from '../../api/static';
import classnames from 'classnames';

interface TicketBarProps {
  name?: string;
  value?: string;
  tags?: string[];
  iconStyle?: 'green' | 'gray';
  btnList?: { name: string; origin: Record<string, any> }[];
  onEvent?: (btn: Record<string, any>) => void;
}

export const TicketBar = (props: TicketBarProps): React.ReactElement => {
  const { name = '', value = '', tags = [], iconStyle = 'green', onEvent = noop, btnList = [] } = props;
  return (
    <div className={getClassName('TicketBar')}>
      <div className="wrapper">
        <div className="left">
          <div className={classnames('icon', iconStyle)}>
            <img src={getStaticUrl('todo.svg')} className="gray" />
            <img src={getStaticUrl('finish.svg')} className="green" />
          </div>
          <div className="title">
            <div className="name">{name}：</div>
            <div className="text">{value}</div>
          </div>
          <div className="label">
            {tags.map((val) => {
              return (
                <div className="item" key={val}>
                  {val}
                </div>
              );
            })}
          </div>
        </div>
        <div className="right">
          {btnList.map((obj) => {
            return (
              <div className="item" key={obj.name} onClick={() => onEvent(obj.origin)}>
                {obj.name}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
