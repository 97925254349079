import React, { useMemo, useRef, useLayoutEffect, useState, useEffect } from 'react';
import './index.less';
import { getClassName, noop } from '../Base';
import { MyTodoV2, TypeToDo } from '../MyTodoV2';
import { MeasureCard } from '../MeasureCard';
import { EmptyBlock } from '../EmptyBlock';
import { Toolbar } from '../Toolbar';
import { TicketBrand } from '../TicketBrand';
import { TicketLayout, TicketLayoutProps, TypeTicketLayoutNode } from '../TicketLayout';
import { TicketBar } from '../TicketBar';
import { Spin, ConfigProvider, zhCN } from '../AntD';
import classnames from 'classnames';
import {
  CooperationJobList,
  BizOrderOrderCodes,
  BizOrderBaseInfo,
  CooperationProcess,
  BizOrderWholeStatus,
  BizOrderAddRights,
  CooperationBtns,
} from '../../api/resource';
import { TicketNodePrimaryProps } from '../TicketNodePrimary';

const NODE_HEIGHT = 200;
const NODE_HEIGHT_BOTTOM = 250;
const NODE_SPACE = 30;
const ITEMS_NODE_ID_LIST = ['agent_purchase-billManager', 'self_purchase-billManager'];

const TASK_STATUS = {
  INIT: 'init',
  RUNNING: 'running',
  FINISH: 'finish',
  WAIT_REVIEW: 'waitReview',
  WAIT_UPLOAD: 'waitUpload',
};

const TASK_STATUS_ICON_MAP: Record<string, 'gray' | 'process' | 'green' | 'wait-upload' | 'wait-review'> = {
  [TASK_STATUS.INIT]: 'gray',
  [TASK_STATUS.RUNNING]: 'process',
  [TASK_STATUS.FINISH]: 'green',
  [TASK_STATUS.WAIT_REVIEW]: 'wait-review',
  [TASK_STATUS.WAIT_UPLOAD]: 'wait-upload',
};

type TypeColorStyle = 'gray' | 'blue' | 'green' | 'blue-process' | 'green-process';
const getColorStyleByStatus = (status: string, colorStyle: 'blue' | 'green'): TypeColorStyle => {
  if (status === 'init') {
    return 'gray';
  }
  if (status === 'finish') {
    return colorStyle;
  }
  if (status === 'running') {
    return `${colorStyle}-process`;
  }
  return colorStyle;
};

const EVENT: Record<string, string> = {
  TODO: 'TODO',
  CREATE_BIZ_ORDER: 'CREATE_BIZ_ORDER',
  NEW_BILL: 'NEW_BILL',
  SELECT_ORDER: 'SELECT_ORDER',
  SELECT_BILL: 'SELECT_BILL',
  'TASK-NEW': 'TASK-NEW',
  'TASK-MODIFY': 'TASK-MODIFY',
  'TASK-REVIEW': 'TASK-REVIEW',
  'TASK-VIEWATTR': 'TASK-VIEWATTR',
  'TASK-VIEW': 'TASK-VIEW',
  'TASK-ADD': 'TASK-ADD',
  BRAND: 'BRAND',
};

type TypeOrder = {
  ticket: string;
  type: string;
  id: number;
};

interface PageFlowProps {
  orderType?: 'self' | 'agent';
  orderId?: number;
  billId?: number;
  onEvent?: (event: string, params: Record<string, any>) => void;
  processRefreshKey?: string;
  subProcessRefreshKey?: string;
  orderRefreshKey?: string;
  todoRefreshKey?: string;
  todoRefreshAutoInterval?: number;
}

export const PageFlow = (props: PageFlowProps): React.ReactElement => {
  const {
    onEvent = noop,
    processRefreshKey = '',
    subProcessRefreshKey = '',
    orderRefreshKey = '',
    todoRefreshKey = '',
    todoRefreshAutoInterval = 0,
    orderType: propOrderType = 'agent',
    orderId: propOrderId = null,
    billId: propBillId = null,
  } = props;

  const sizeDom1 = useRef<HTMLDivElement>(null);
  const sizeDom2 = useRef<HTMLDivElement>(null);
  const [width1, setWidth1] = useState(0);
  const [width2, setWidth2] = useState(0);
  const [height2, setHeight2] = useState(0);

  const [innerTodoRefreshKey, setInnerTodoRefreshKey] = useState(0);
  const todoRefreshTimer = useRef<ReturnType<typeof setTimeout> | null>(null);

  /* 数据逻辑，开始 */

  const [type, setType] = useState<'self' | 'agent'>(propOrderType);
  const colorStyle = type === 'self' ? 'green' : 'blue';
  const [order, setOrder] = useState<TypeOrder | null>(null);
  const [subOrder, setSubOrder] = useState<Record<'bizId' | string, any> | null>(null);

  useEffect(() => {
    setOrder(null);
    setSubOrder(null);
  }, [type]);

  useEffect(() => {
    // 后面选择第一个
    setSubOrder(null);
  }, [order]);

  useEffect(() => {
    setType(propOrderType);
  }, [propOrderType]);

  const todoListReq = CooperationJobList().useRequest({
    refreshDeps: [todoRefreshKey, innerTodoRefreshKey],
  });
  const todoList: TypeToDo[] = (todoListReq.data?.data || []).map((obj) => {
    return { ticket: obj.bizCode, title: obj.jobDesc, actionName: obj.actionTitle, id: obj.id };
  });

  const orderListType = type === 'agent' ? 'agent_purchase' : 'self_purchase';
  const orderListReq = BizOrderOrderCodes({ orderType: orderListType }).useRequest({
    refreshDeps: [orderListType, orderRefreshKey],
  });

  const orderList: { ticket: string; type: string; id: number }[] = useMemo(() => {
    return (orderListReq.data?.data || []).map((obj) => {
      return {
        ticket: obj.orderCode,
        type: obj.orderType,
        id: obj.id,
      };
    });
  }, [orderListReq.data?.data]);

  useEffect(() => {
    if (!propOrderId) {
      return;
    }
    const obj = orderList.find((obj) => obj.id === propOrderId);
    if (obj) {
      setOrder(obj);
    }
  }, [propOrderId, orderList]);

  // 判断权限
  const permissionsReq = BizOrderAddRights({ orderType: orderListType }).useRequest({
    refreshDeps: [orderListType],
  });
  const canAddBizOrder = !!permissionsReq.data?.data?.canAddBizOrder;
  const canAddBill = !!permissionsReq.data?.data?.canAddBill;

  // 选中第一个业务单
  useEffect(() => {
    if (propOrderId) {
      return;
    }
    if (!orderList.length) {
      setOrder(null);
      return;
    }

    // 已有的话，就不改了
    if (!!order) {
      return;
    }

    const obj = orderList[0];
    setOrder(obj);
  }, [orderListReq.data?.data]);

  const orderOrigin = order ? (orderListReq.data?.data || []).find((obj) => obj.id === order.id) : null;

  const orderBaseInfoReq = BizOrderBaseInfo({ id: order?.id }).useRequest({
    refreshDeps: [order],
    ready: !!order,
  });
  const orderBaseInfo = orderBaseInfoReq.data?.data;

  const topProcessReq = CooperationProcess({ bizId: order?.id, bizType: 'order' }).useRequest({
    refreshDeps: [order, processRefreshKey],
    ready: !!order,
  });
  const topProcessCode = topProcessReq.data?.data?.processCode || '';
  const topArrowMap: Record<string, string> = {};
  const topProcessNodeList: TypeTicketLayoutNode[] = (topProcessReq.data?.data?.nodeList || []).map(
    (obj, idx) => {
      topArrowMap[`${idx + 1}-${idx + 2}`] = obj.arrowType === 'solid' ? colorStyle : 'weak';
      const id = `${topProcessCode}-${obj.nodeCode || obj.name}`;
      const title = obj.nodeName || obj.title;
      const isSpecial = obj.nodeCode === 'billManager';
      let footerStyle = isSpecial ? 'arrow' : 'empty';
      if (footerStyle === 'arrow') {
        if (!canAddBill) {
          footerStyle = 'empty-arrow';
        }
        if (obj.status !== 'running') {
          footerStyle = 'empty-arrow';
        }
      }
      return {
        type: 'primary',
        id,
        props: {
          number: (idx + 1).toString(),
          title,
          height: NODE_HEIGHT.toString() + 'px',
          colorStyle: getColorStyleByStatus(obj.status, colorStyle),
          footerStyle,
          itemTooltip: isSpecial ? '' : '',
          itemList: (obj.taskList || []).map((o) => {
            if (isSpecial) {
              return {
                text: o.taskCode,
                iconStyle: TASK_STATUS_ICON_MAP[o.taskContentStatus],
                textStyle: o.status === 'finish' ? 'normal' : 'gray',
                itemStyle: subOrder?.bizId === o?.bizId ? 'selected' : 'normal',
                actionList: [],
                origin: o,
              };
            }
            const actionList = (o.actionList || [])
              .map((action) => {
                if (action.uiType === 'view') {
                  return '$VIEW';
                }
                if (action.uiType === 'view_attr') {
                  return '$VIEWATTR';
                }
                if (action.uiType === 'review') {
                  return '$REVIEW';
                }
                if (action.uiType === 'modify') {
                  return '$MODIFY';
                }
                if (action.uiType === 'new') {
                  return '$NEW';
                }
                if (action.uiType === 'additional') {
                  return '$ADD';
                }
                return null;
              })
              .filter((val) => !!val);
            return {
              text: o.taskName,
              iconStyle: TASK_STATUS_ICON_MAP[o.taskContentStatus],
              textStyle: o.status === 'finish' ? 'normal' : 'gray',
              actionList: actionList as string[],
              indent: Number(o.leftIndent),
              origin: o,
            };
          }),
        },
      };
    },
  );

  const billListNode = topProcessNodeList.find(
    (node) =>
      ((node.props as TicketNodePrimaryProps)?.footerStyle || '') in { arrow: true, 'empty-arrow': true },
  );
  if (billListNode && propBillId) {
    const billNode = (billListNode.props.itemList || []).find((obj) => obj.origin?.bizId === propBillId);
    if (billNode && billNode.origin && !subOrder) {
      setSubOrder(billNode.origin);
    }
  }

  // 选中第一个提单
  useEffect(() => {
    if (propBillId) {
      return;
    }
    if (!billListNode) {
      setSubOrder(null);
      return;
    }

    // 有的话就不改
    if (!!subOrder) {
      return;
    }

    const billNode = billListNode.props.itemList?.[0];
    if (!billNode || !billNode.origin) {
      setSubOrder(null);
      return;
    }
    setSubOrder(billNode.origin);
  }, [topProcessReq.data?.data?.nodeList]);

  const wholeStatusReq = BizOrderWholeStatus().useRequest();
  const selfMeasure = wholeStatusReq.data?.data?.self_purchase || null;
  const agentMeasure = wholeStatusReq.data?.data?.agent_purchase || null;

  const subOrderId = subOrder ? subOrder?.bizId?.toString() : null;
  const subArrowMap: Record<string, string> = {};
  const subProcessReq = CooperationProcess({ bizId: subOrderId, bizType: 'lading_bill' }).useRequest({
    refreshDeps: [subOrderId, subProcessRefreshKey],
    ready: !!subOrderId,
  });
  const subProcessNodeList: TypeTicketLayoutNode[] = (subProcessReq.data?.data?.nodeList || []).map(
    (obj, idx) => {
      subArrowMap[`${idx + 1}-${idx + 2}`] = obj.arrowType === 'solid' ? colorStyle : 'weak';
      const id = `${topProcessCode}-${obj.nodeCode || obj.name}`;
      const title = obj.nodeName || obj.title;
      return {
        type: 'node',
        id,
        props: {
          number: (idx + 1).toString(),
          title,
          height: NODE_HEIGHT_BOTTOM.toString() + 'px',
          colorStyle: getColorStyleByStatus(obj.status, colorStyle),
          footerStyle: 'empty',
          itemTooltip: '',
          itemList: (obj.taskList || []).map((o) => {
            const actionList = (o.actionList || [])
              .map((action) => {
                if (action.uiType === 'view') {
                  return '$VIEW';
                }
                if (action.uiType === 'view_attr') {
                  return '$VIEWATTR';
                }
                if (action.uiType === 'review') {
                  return '$REVIEW';
                }
                if (action.uiType === 'modify') {
                  return '$MODIFY';
                }
                if (action.uiType === 'new') {
                  return '$NEW';
                }
                if (action.uiType === 'additional') {
                  return '$ADD';
                }
                return null;
              })
              .filter((val) => !!val);
            return {
              text: o.taskName,
              iconStyle: TASK_STATUS_ICON_MAP[o.taskContentStatus],
              textStyle: o.status === 'finish' ? 'normal' : 'gray',
              actionList: actionList as string[],
              indent: Number(o.leftIndent),
              origin: o,
            };
          }),
        },
      };
    },
  );

  // sub 右上的按钮
  const btnsReq = CooperationBtns().useRequest();
  const subBtnList = btnsReq.data?.data || [];

  /* 数据逻辑，结束 */

  useEffect(() => {
    if (!todoRefreshAutoInterval) {
      return;
    }
    if (todoListReq.loading) {
      return;
    }
    if (todoRefreshTimer.current) {
      clearTimeout(todoRefreshTimer.current);
    }
    todoRefreshTimer.current = setTimeout(
      () => {
        setInnerTodoRefreshKey(innerTodoRefreshKey + 1);
      },
      todoRefreshAutoInterval >= 2000 ? todoRefreshAutoInterval : 2000,
    );
    return () => {
      if (todoRefreshTimer.current) {
        clearTimeout(todoRefreshTimer.current);
      }
    };
  }, [innerTodoRefreshKey, todoRefreshKey, todoList]);

  const onResize = () => {
    setWidth1(sizeDom1.current?.clientWidth || 0);
    setWidth2(sizeDom2.current?.clientWidth || 0);
  };

  useLayoutEffect(() => {
    setWidth1(sizeDom1.current?.clientWidth || 0);
  }, [sizeDom1?.current]);
  useLayoutEffect(() => {
    setWidth2(sizeDom2.current?.clientWidth || 0);
  }, [sizeDom2?.current]);

  useLayoutEffect(() => {
    window.addEventListener('resize', onResize);
    onResize();
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  /* 开始事件处理 */
  const TASK_EVENT_MAP = {
    'TASK-NEW': true,
    'TASK-MODIFY': true,
    'TASK-VIEWATTR': true,
    'TASK-VIEW': true,
    'TASK-REVIEW': true,
    'TASK-ADD': true,
  };
  const onLeftRightEvent = (node: TypeTicketLayoutNode, event: string, params?: Record<string, any>) => {
    if (event === 'NEW_BILL') {
      onEvent(EVENT.NEW_BILL, { order: orderOrigin });
      return;
    }
    if (event === 'ITEM') {
      if (ITEMS_NODE_ID_LIST.includes(node.id)) {
        const bizId = params?.item?.origin?.bizId;
        if (!bizId) {
          return;
        }
        setSubOrder(params.item.origin);
        onEvent(EVENT.SELECT_BILL, { bill: params.item.origin, order: orderOrigin });
        return;
      }
    }

    if (event in TASK_EVENT_MAP) {
      const nodeList = topProcessReq.data?.data?.nodeList || [];
      const idx = topProcessNodeList.indexOf(node);
      onEvent(EVENT[event], {
        order: orderOrigin,
        bill: null,
        node: { ...nodeList[idx] },
        task: params?.task?.origin,
      });
    }
  };

  const onSubEvent = (node: TypeTicketLayoutNode, event: string, params?: Record<string, any>) => {
    if (event in TASK_EVENT_MAP) {
      const nodeList = subProcessReq.data?.data?.nodeList || [];
      const idx = subProcessNodeList.indexOf(node);
      onEvent(EVENT[event], {
        order: orderOrigin,
        bill: subOrder,
        node: { ...nodeList[idx] },
        task: params?.task?.origin,
      });
    }
  };

  /* 事件处理结束 */

  const genMainEmpty = () => {
    if (!!order) {
      return null;
    }
    return (
      <div className="main">
        <div className="full">
          <EmptyBlock />
        </div>
      </div>
    );
  };

  const genMainLeftRight = () => {
    if (!order) {
      return (
        <div className="main hide">
          <div className="left"></div>
          <div className="right">
            <div className="box">
              <div className="inner" ref={sizeDom1}></div>
            </div>
          </div>
        </div>
      );
    }

    const layoutProps = {
      fullWidth: 1028,
      nodeMinWidth: 200,
      nodeMaxWidth: 235,
      arrowWidth: 32,
      arrowStyleMap: topArrowMap,
      itemList: topProcessReq.loading ? [] : topProcessNodeList,
    };

    let contractCode = orderBaseInfo?.contractCode;
    if (!orderBaseInfoReq.loading) {
      if (orderBaseInfo?.contractUpload) {
        if (!contractCode) {
          contractCode = '--';
        }
      }
    }

    return (
      <div className="main" key={order.id}>
        <div className="left">
          <Spin spinning={orderBaseInfoReq.loading}>
            <TicketBrand
              colorStyle={colorStyle}
              title={''}
              ticket={contractCode}
              tags={orderBaseInfoReq.loading ? [] : orderBaseInfo?.tags || []}
              pairList={orderBaseInfoReq.loading ? [] : orderBaseInfo?.fields || []}
              onClick={() => {
                onEvent(EVENT.BRAND, { order: orderOrigin });
                return;
              }}
            />
          </Spin>
        </div>
        <div className="right">
          <div className={classnames('box', colorStyle)}>
            <div className="inner" ref={sizeDom1}>
              <div className="layout">
                <Spin spinning={topProcessReq.loading}>
                  <TicketLayout
                    {...(layoutProps as TicketLayoutProps)}
                    fullWidth={width1}
                    mustOneRow={true}
                    onEvent={onLeftRightEvent}
                  />
                </Spin>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const genMainFull = () => {
    if (!order) {
      return null;
    }
    if (!subOrder) {
      return (
        <>
          <div className="main">
            <div className="full">
              <EmptyBlock text="请选择运输单" />
            </div>
          </div>
          <div className="main hide">
            <div className="full">
              <div className="box">
                <div className="inner" ref={sizeDom2}></div>
              </div>
            </div>
          </div>
        </>
      );
    }
    const layoutProps = {
      fullWidth: 1028,
      nodeMinWidth: 200,
      nodeMaxWidth: 290,
      arrowWidth: 30,
      arrowStyleMap: subArrowMap,
      itemList: subProcessReq.loading ? [] : subProcessNodeList,
    };

    return (
      <div className="main">
        <div className="full">
          <div className="box">
            <div className="bar">
              <TicketBar
                name="海运提单号"
                iconStyle={subOrder?.status === 'finish' ? 'green' : 'gray'}
                value={subOrder?.taskCode}
                tags={subOrder.billTypeDesc ? [subOrder?.billTypeDesc] : []}
                btnList={subBtnList.map((obj: Record<string, any>) => {
                  return { name: obj.name, origin: { ...obj } };
                })}
                onEvent={(origin) => {
                  onEvent(`SUB-TOOLBAR-BTN`, { order: orderOrigin, bill: subOrder, btn: origin });
                }}
              />
            </div>
            <div className="inner" ref={sizeDom2} style={{ height: height2 }}>
              <div className="layout">
                <Spin spinning={subProcessReq.loading}>
                  <TicketLayout
                    {...(layoutProps as TicketLayoutProps)}
                    fullWidth={width2}
                    onLayout={(layout) => {
                      const rows = layout.layout.length;
                      setHeight2(rows * NODE_HEIGHT_BOTTOM + (rows - 1) * NODE_SPACE);
                    }}
                    onEvent={onSubEvent}
                  />
                </Spin>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <ConfigProvider locale={zhCN}>
      <div className={getClassName('PageFlow')}>
        <div className="wrapper">
          <div className="top">
            <div className="left">
              <div className="inner">
                <div className="measure">
                  <div className="item">
                    <Spin spinning={wholeStatusReq.loading}>
                      <MeasureCard
                        color="#1C6CDC"
                        title="代理采购业务"
                        itemList={[
                          { name: '在途', value: agentMeasure ? agentMeasure.onTheWay.toString() : '--' },
                          { name: '已完成', value: agentMeasure ? agentMeasure.finished.toString() : '--' },
                          { name: '总计', value: agentMeasure ? agentMeasure.sum.toString() : '--' },
                        ]}
                      />
                    </Spin>
                  </div>
                  <div className="item">
                    <Spin spinning={wholeStatusReq.loading}>
                      <MeasureCard
                        color="#17A369"
                        title="自主采购业务"
                        itemList={[
                          { name: '在途', value: selfMeasure ? selfMeasure.onTheWay.toString() : '--' },
                          { name: '已完成', value: selfMeasure ? selfMeasure.finished.toString() : '--' },
                          { name: '总计', value: selfMeasure ? selfMeasure.sum.toString() : '--' },
                        ]}
                      />
                    </Spin>
                  </div>
                </div>
              </div>
            </div>
            <div className="right">
              <div className="inner">
                <div className="todo">
                  <Spin spinning={todoRefreshAutoInterval ? false : todoListReq.loading}>
                    <MyTodoV2
                      itemList={todoList}
                      onItem={(item) => {
                        const originList = todoListReq.data?.data || [];
                        const obj = originList.find((obj) => obj.id === item.id);
                        onEvent(EVENT.TODO, { item: obj });
                      }}
                    />
                  </Spin>
                </div>
              </div>
            </div>
          </div>
          <div className="toolbar" key={type}>
            <Toolbar
              type={type}
              currentTicket={order?.ticket}
              ticketList={orderList}
              hideButton={!canAddBizOrder}
              onType={(type) => {
                setType(type);
                onEvent(EVENT.SELECT_ORDER, { order: null });
              }}
              onTicket={(obj) => {
                setOrder(obj);
                const orderOrigin = (orderListReq.data?.data || []).find((order) => order.id === obj.id);
                onEvent(EVENT.SELECT_ORDER, { order: orderOrigin });
              }}
              onCreate={() => onEvent(EVENT.CREATE_BIZ_ORDER, { type })}
            />
          </div>
          {genMainEmpty()}
          {genMainLeftRight()}
          {genMainFull()}
        </div>
      </div>
    </ConfigProvider>
  );
};
